<template>
  <div>
    <Header 
      button="Carrinho"
      title="Nossos produtos"
      subtitle="Explore nossas soluções e confira suas especificações e materiais de apoio."
      @click="$router.push('/carrinho')"
    >
      <div class="mt-5 grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-4">
          <label for="pesquisar" class="block text-sm font-medium text-gray-700">Pesquisar</label>
          <input v-model="search" @input="filterProducts('search')" type="text" name="pesquisar" id="pesquisar" placeholder="Pesquise pelo título" autocomplete="pesquisar-name" class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
        </div>
        <div class="col-span-6 sm:col-span-2">
          <Select name="categoria" label="Categoria" :values="categoriesUsed" v-model="categorieFilter" @input="filterProducts('filter')" />
        </div>
      </div>
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none" style="filter: invert(3%);">
        <div @click="$router.push(`/produto/${item.id}`)" v-for="(item, index) in productsFiltered.length ? productsFiltered : products" :key="index" class="bg-white flex flex-col rounded-lg shadow hover:shadow-lg overflow-hidden">
          <div class="relative">
            <img class="h-56 w-full object-contain" :src="item.image" alt="">
            <span class="absolute bottom-2 left-4 px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary text-white">{{ item.price | currency }}</span>
            <span v-if="item.unavailable" class="absolute top-2 left-4 px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-500 text-white">INDISPONÍVEL</span>
          </div>
          <div class="p-4 flex items-center">
            <div class="w-4/5">
              <div class="inline-flex items-center text-sm font-medium text-primary">
                <svg class="h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
                <span class="ml-1 antialiased">{{ item.categorie }}</span>
              </div>
              <p class="text-xl font-semibold text-gray-900 truncate">
                {{ item.titleDestaque || item.title }}
              </p>
            </div>
            <div class="w-full text-right">
              <button @click.stop="item.unavailable ? '' : addCart(item.id)" :disabled="item.unavailable" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" :class="{'opacity-50': item.unavailable}">
                <svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Header>
  </div>
</template>

<script>
import idb from '@/idb'
import { listingsColl } from '@/firebase'

export default {
  components: {
    Select: () => import('@/components/form/select'),
    Header: () => import('@/components/layout/header'),
  },

  data() {
    return {
      search: '',
      products: [],
      productFocus: '',
      productsFiltered: [],
      categories: [],
      categorieFilter: '',
    }
  },

  computed: {
    categoriesUsed: function() {
      return this.products.filter((thing, index, self) => self.findIndex(t => t.categorie === thing.categorie) === index).map(item => item.categorie)
    }
  },

  mounted() { this.getProducts(this.$router.currentRoute.params.id) },

  methods: {
    async getProducts(cat = '') {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Products').get()
      .then((result) => { this.products = (result.data().available || []).filter(i => !i.invisible) })
      .catch(() => { this.$toast.error('Falha ao obter os produtos, tente novamente') })
      if (cat && this.products.find(item => item.categorie === cat).id) { this.categorieFilter = cat, this.filterProducts('filter') }
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async addCart(item) {
      await idb.saveField(item, '1')
      this.$toast.success('Produto adicionado ao carrinho')
    },

    filterProducts(origin) {
      if (origin === 'search') {
        this.categorieFilter = ''; this.productsFiltered = this.products.filter((item) => { return item.title.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 })
      }
      if (origin === 'filter') {
        this.search = ''; this.productsFiltered = this.products.filter(item => item.categorie === this.categorieFilter)
      }
    }
  },

  watch: {
    '$route'() {
      this.getProducts(this.$router.currentRoute.params.id)
    }
  },

  filters: {
    currency(val) {
      var currency = parseFloat(val)
      return currency.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }
  }
}
</script>