const DB_NAME = "vwsolucoesDB";
const DB_VERSION = 1;
let DB;

export default {

  async getDb() {
    return new Promise((resolve, reject) => {
    
      if (DB) {
        return resolve(DB)
      }

      let request = window.indexedDB.open(DB_NAME, DB_VERSION)

      request.onerror = () => {
        reject("Error")
      }

      request.onsuccess = (e) => {
        DB = e.target.result
        resolve(DB)
      }

      request.onupgradeneeded = (e) => {
        let db = e.target.result
        db.createObjectStore("creator")
      }
    })
  },

  async clearDb() {
    let db = await this.getDb()

    return new Promise((resolve) => {

      let trans = db.transaction(["creator"], "readwrite")
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore("creator")
      store.clear()
    })
  },

  async deleteField(key) {
    let db = await this.getDb()

    return new Promise((resolve) => {

      let trans = db.transaction(["creator"], "readwrite")
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore("creator")
      store.delete(key)
    })
  },

  async getFields() {
    let db = await this.getDb()

    return new Promise((resolve) => {

      let trans = db.transaction(["creator"], "readonly")
      trans.oncomplete = () => {
        resolve(fields)
      }

      let store = trans.objectStore("creator")
      let fields = {}

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result
        if (cursor) {
          fields[cursor.key] = cursor.value
          cursor.continue()
        }
      }
    })
  },

  async saveField(key, field) {
    let db = await this.getDb()

    return new Promise((resolve) => {

      let trans = db.transaction(["creator"], "readwrite")
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore("creator")
      store.put(field, key)
    })
  },
}